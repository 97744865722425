<template>
    <div v-if="isLoading"></div>
    <div v-else>
        <v-card>
            <v-card-title class="title-post">
                <span> {{ title }}</span>
            </v-card-title>

            <!-- Edited users-->
            <v-card-text>
                <div
                    class="d-flex justify-center"
                    style="min-height: 200px"
                >
                    <img
                        v-if="getImage"
                        :src="getImage"
                        height="200px"
                    >
                </div>
                <div style="margin: 0 auto; text-align: center;">
                    <span>{{ $t('awards.winner.subtitle') }}</span>
                </div>
            </v-card-text>

            <div>
                <!--                <div
                    v-for="(item, index) in dataWinners"
                    :key="index"
                    style=""
                >-->
                <!--                    <div class="title-post mb-5 text-color-dark">
                        {{ typeRecognition(item.VALOR).toUpperCase() }}
                    </div>-->

                <div
                    style="display: grid; grid-template-columns: repeat(auto-fill, minmax(min(450px, 100%), 1fr));"
                    class="mb-5"
                >
                    <!--              item.ITEMS-->
                    <div
                        v-for="(subitem, subindex) in dataWinners"
                        :key="subindex"
                        style="display: grid; grid-template-columns: auto minmax(20%, 80%);"
                        class="pa-2 ma-1 base-card-buttons rounded-xl"
                        @click="goRecognitions(subitem.NOMBRE)"
                    >
                        <v-hover v-slot="{ hover }">

                            <v-avatar
                                size="100"
                                class="mr-2"
                                width="100"
                                height="125"
                                @click.stop="showImageFullScreen(getImagePersonURL(subitem.FOTO))"
                            >
                                <v-img
                                    :src="getImagePersonURL(subitem.FOTO)"
                                />

                                <v-fade-transition>
                                    <v-overlay
                                        v-if="hover"
                                        absolute
                                        color="#036358"
                                    >
                                        <v-btn
                                            icon
                                            x-large>

                                            <v-icon>mdi-fullscreen</v-icon>
                                        </v-btn>
                                    </v-overlay>
                                </v-fade-transition>

                            </v-avatar>



                        </v-hover>
                        <div>
                            <div>{{ subitem.NOMBRE_CORTO }}</div>
                            <div class="text-small">
                                {{ subitem.CENTRO }}
                            </div>
                            <div class="text-small">
                                {{ subitem[`AREA_${getLang.toUpperCase()}`] }}
                            </div>
                            <div class="text-small text-color-dark">
                                {{ typeRecognition(subitem.type) }}
                            </div>
                        </div>
                    </div>
                </div>
                <!--                </div>-->
            </div>
        </v-card>

        <overlay-full-screen-video
            :overlay="overlayVideo"
            :poster="`${getServerURL}/videos/mov_bbb-poster.jpeg`"
            @update:overlay="overlayVideo = $event"
        >
            <source
                :src="`${getServerURL}/videos/winners_${getLang}.mp4`"
                type="video/mp4"
            >
        </overlay-full-screen-video>

        <OverlayFullScreenImage
            ref="imageFullScreen"
        />
    </div>
</template>

<script>

import axios from '@/plugins/axios'
import {mapGetters} from 'vuex'

import postMixin from '@/mixins/postMixin'
import OverlayFullScreenVideo from "@/components/common/OverlayFullScreenVideo";
import typesRecognitions from '@/data/typesRecognitions'
import OverlayFullScreenImage from "@/components/common/OverlayFullScreenImage";


export default {
    name: 'Winners',
    components: {OverlayFullScreenVideo, OverlayFullScreenImage},
    mixins: [postMixin],
    data: () => ({
        overlayVideo: false,
        isLoading: false,
        winnersByValue: []
    }),
    computed: {
        title() {
            const attributes = this.post.attributes
            return attributes.title2 ?? attributes.title
        },
        getServerURL() {
            return process.env?.VUE_APP_API_SERVER;
        },
        getImage() {
            if (this.post.attributes.image2) {
                return process.env.VUE_APP_API_ENDPOINT + 'imagesSimple?filename=posts/images/' + this.post.attributes.image2
            }

            return ''
        },
        dataWinners() {
            const result = [];
            this.winnersByValue.forEach(types => {
                result.push(...types.ITEMS.map(item => ({...item, type: types.VALOR})));
            });
            return result.sort((a, b) => a.NOMBRE_CORTO > b.NOMBRE_CORTO ? 1 : -1);
        },
        ...mapGetters("languages", ['getLang'])
    },
    async created() {
        this.isLoading = true;
        this.$store.dispatch('loading/start');

        try {
            const config = await this.getDataWinners();
            if (config) {
                this.isLoading = false;
            }

        } catch (e) {
        }


        this.$store.dispatch('loading/end');

        if (this.isLoading) {
            return this.$router.push('/');
        }
    },
    async mounted() {
        this.overlayVideo = !await this.$store.dispatch('cache/getAndUpdatePersistentInfo', {
            key: 'winners13-video',
            value: true
        });
    },
    methods: {
        async getDataWinners() {
            try {
                const winners = await axios.get('getWinners');
                const jsonWinners = winners.data?.jsonWinners;
                this.winnersByValue = jsonWinners ?? [];

                return true;
            } catch (e) {
                return false;
            }

        },
        typeRecognition(idRecognition) {
            const type = typesRecognitions.find(t => t.id == idRecognition);
            if (type) {
                return type[`name_${this.getLang}`];
            }
            return "";
        },

        goRecognitions(filterName) {
            this.$router.push({name: 'VoteRecognitionsView', params: {filterName}});
            // /votation
        },
        getImagePersonURL(picture) {
            const serverURL = this.getServerURL;
            return `${serverURL}/winners/img/${picture}`
        },
        showImageFullScreen(image){
            this.$refs.imageFullScreen.showImage(image);
        }
    }

}
</script>

<style scoped lang="scss">
@import "../../../assets/scss/octavia-design-system/variables/colors";

.base-card-buttons {
    cursor: pointer;
    border: 1px solid transparent;
}

.base-card-buttons:hover {
    border: 1px $secondary solid !important;
}
</style>
