<template>
    <v-fade-transition>
        <v-overlay
            z-index="99"
            :value="overlay"
            opacity="0.95"
        >
            <div
                class="text-right mr-4"
                @click.stop="hideImage"
            >
                <v-icon
                    color="#fff"
                >
                    mdi-close
                </v-icon>
            </div>

            <div style="display: flex; justify-content: center; max-width:100vw; max-height:95vh">

                <v-img
                    :src="image"
                    max-height="100%"
                    max-width="100%"
                    contain

                />
            </div>
        </v-overlay>
    </v-fade-transition>
</template>

<script>
export default {
    name: "OverlayFullScreenImage",
    data(){
        return {
            overlay: false,
            image: null
        }
    },
    methods:{
        showImage(image){
            this.image = image;
            this.overlay = true;
        },
        hideImage(){
            this.overlay = false;
        }
    }
}
</script>

<style scoped>

</style>
